<script setup lang="ts">
const { t } = useI18n({
  useScope: "global",
});

const client = useClient();

const { data, status } = await client.balance.balance.useQuery();
const balanceDescription = computed(() => {
  if (!data.value) {
    return ''
  }
  if (!data.value.fundsReleased) {
    return ''
  }

  return `${t('account.balance.fundsReleased')}: -${data.value.fundsReleased}`
})
</script>

<template>
  <div
    v-if="status == 'success' && data.show"
    class="balance"
  >
    <div>
      <span :title="balanceDescription ? balanceDescription : null"><FormatCurrencyColor :value="data.balance" /></span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.balance {
  align-items: center;
  margin-right: 20px;
  font-size: 12px;
  font-weight: 500;
  color: var(--text-color-1);
  img {
    margin-right: 10px;
  }
  text-align: right;
}
</style>
