<script setup lang="ts">
import {useSelectedCarsStore} from "~/stores/selected-cars";

const carsStore = useSelectedCarsStore();

const toggle = () => {
  carsStore.popupOpen = !carsStore.popupOpen;
};
const openInvoice = () => {
  carsStore.popupOpen = false;
  carsStore.runAction('invoice');
};
const onRemoveCar = (id) => {
  carsStore.removeCar(id);
};
</script>

<template>
  <Teleport to="#teleports">
    <AppCard
      v-show="carsStore.cars.length > 0"
      class="selected-cars"
      :class="{ open: carsStore.popupOpen }"
    >
      <div
        class="header"
        @click="toggle"
      >
        Cars selected ({{ carsStore.cars.length }})
      </div>
      <div
        v-show="carsStore.popupOpen"
        class="content"
      >
        <div class="content-items">
          <div
            v-for="car in carsStore.list"
            :key="car?.id"
            class="car-item"
          >
            <NuxtLink :to="`/dealer/cars/${car.id}`">
              {{ car.title }}
            </NuxtLink>
            <div
              class="remove-car"
              @click="onRemoveCar(car.id)"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 0.0625C3.61562 0.0625 0.0625 3.61562 0.0625 8C0.0625 12.3844 3.61562 15.9375 8 15.9375C12.3844 15.9375 15.9375 12.3844 15.9375 8C15.9375 3.61562 12.3844 0.0625 8 0.0625ZM11.7375 10.6125C12.0469 10.9219 12.0469 11.425 11.7375 11.7344C11.4219 12.05 11.0438 12.1625 10.6156 11.7344L8 9.12187L5.3875 11.7344C5.025 12.0969 4.5 11.9688 4.26562 11.7344C3.95625 11.425 3.95625 10.9219 4.26562 10.6125L6.87813 8L4.26562 5.3875C3.95625 5.07812 3.95625 4.575 4.26562 4.26562C4.575 3.95625 5.07812 3.95625 5.3875 4.26562L8 6.87813L10.6125 4.26562C10.9219 3.95625 11.425 3.95625 11.7344 4.26562C12.0437 4.575 12.0437 5.07812 11.7344 5.3875L9.12187 8L11.7375 10.6125Z"
                  fill="#1F163B"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="actions">
          <FormKit
            type="button"
            variant="outline"
            @click="carsStore.clearCars"
          >
            Clear
          </FormKit>
          <FormKit
            type="button"
            variant="primary"
            @click="openInvoice"
          >
            Print Invoice
          </FormKit>
        </div>
      </div>
    </AppCard>
  </Teleport>
</template>

<style lang="scss" scoped>
.content-items {
  margin-bottom: 10px;
}
.selected-cars {
  position: fixed;
  bottom: 0;
  right: 25px;

  padding: 0;
  width: 270px;
  max-width: 100%;
  height: 30px;

  background-color: #fff;
  z-index: 100;
  &.open {
    height: 300px;
  }
}

.content {
  height: calc(100% - 30px);
  overflow: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

.content-items {
  display: flex;
  flex-direction: column;
}

.header {
  padding: 7px;
  //background: var(--primary-color);
  //border-bottom: 1px solid var(--primary-color);
  border-radius: 5px;

  //color: #fff;
  cursor: pointer;
  text-align: center;

  font-size: 12px;
  line-height: 14px;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.car-item{
  padding: 5px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;

  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  &:hover {
    background: #eee;
  }
  a {
    color: var(--primary-color);
    text-decoration: none;
    &:hover {
      color: var(--primary-hover-color);
    }
  }
}
</style>
