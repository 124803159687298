<script lang="ts" setup>
import {useSelectedCarsStore} from "~/stores/selected-cars";
import {useAuthStore} from "~/stores/authUser";

const localePath = useLocalePath();
const authStore = useAuthStore();

const { t } = useI18n({
  useScope: "global",
});

const appConfig = useAppConfig()
const preferences = computed(() => appConfig.preferences);

const menuItems = computed(()=>{
  const menu = [];

  if (authStore.loggedIn) {
    menu.push({
      label: t("nav.calculator"),
      to: localePath("/dealer/calculator"),
    });
    menu.push({
      label: t("nav.cars"),
      to: localePath("/dealer/cars"),
      children: [
        {
          label: t("nav.shipmentRequests"),
          to: localePath("/shipment-requests"),
        },
        {
          label: t("nav.requestedServices"),
          to: localePath(`/dealer/requested-services`),
        },
        {
          label: t("nav.fines"),
          to: localePath(`/fines`),
        },
        {
          label: t("nav.insuranceCondition"),
          to: localePath(`/docs/insurance-conditions`),
        }
      ]
    });

    // menu.push({
    //   label: t("nav.clients"),
    //   to: localePath("/client-services"),
    //   children: []
    // });

    menu.push({
      label: t("nav.inbox"),
      to: localePath(`/inbox`),
    });
  }

  if (!authStore.loggedIn) {
    menu.push({
      label: t("nav.cars"),
      to: localePath("/guest/cars"),
    });
  }

  return menu
})

const menuItemsMobile = computed(()=>{
  const menu = [...menuItems.value];

  menu.push({
    label: t('nav.user.links.profile'),
    to: localePath('dealer-profile'),
  })
  if (authStore.isAdmin) {
    menu.push({
      label: t('nav.user.links.admin'),
      to: localePath('admin'),
    })
    menu.push({
      label: t('nav.user.links.dealer'),
      to: localePath('dealer-cars'),
    })
  }

  menu.push({
    label: t('nav.user.links.logout'),
    itemProps: {
      'data-test': 'logout',
      onClick: () => authStore.logout(),
    }
  })

  return menu
})

//just init
useSelectedCarsStore();

</script>

<template>
  <div class="dealer">
    <ClientOnly>
      <AppSelectedCars />
    </ClientOnly>
    <div class="header-wrapper">
      <ThemeHeader
        logo-to="/dealer/cars"
        logo-path="/img/logo-default.svg"
        :menu-items="menuItems"
        :menu-items-mobile="menuItemsMobile"
      >
        <template #right>
          <AppDealerBalance />
          <AppLangSwitcher />
          <AppNotificationBell />
          <AppDealerManagerInfo v-if="preferences.withManager" />
          <AppUserAuth />
        </template>
        <template #mobile-right>
          <AppLangSwitcher />
          <AppNotificationBell />
          <AppDealerManagerInfo v-if="preferences.withManager" />
        </template>
      </ThemeHeader>
    </div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>

  .dealer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 80px;

    .header-wrapper {
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
    }
  }

</style>
